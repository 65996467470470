import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from "@sentry/angular";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as Parse from 'parse';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environment } from 'src/environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DecoratorService } from './shared/services/decorator.service';
import { SnackbarService } from './shared/services/snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderService } from './shared/services/loader.service';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getGermanPaginatorIntl } from './get-german-paginator-intl';
import { MatDateFormats, MAT_NATIVE_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { OverlayModule } from '@angular/cdk/overlay';
import { MaterialModule } from './material/material.module';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';

export const GRI_DATE_FORMATS: MatDateFormats = {
  ...MAT_NATIVE_DATE_FORMATS,
  display: {
    ...MAT_NATIVE_DATE_FORMATS.display,
    dateInput: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    } as Intl.DateTimeFormatOptions,
  }
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

function initializeParse() {
  return new Promise<void>((resolve, reject) => {
    Parse.initialize(environment.parseAPPID, environment.parseJSKey);
    (Parse as any).serverURL = environment.parseServerURL;
    resolve();
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreModule.forRoot({}, {}),
    BrowserAnimationsModule,
    EffectsModule.forRoot([]),
    NgxPermissionsModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatSnackBarModule,
    MatDialogModule,
    NgxSpinnerModule.forRoot(),
    CodemirrorModule,
    OverlayModule,
    MatSelectModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => initializeParse,
      multi: true,
      deps: [Sentry.TraceService]
    },
    DecoratorService,
    { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
    { provide: MAT_DATE_FORMATS, useValue: GRI_DATE_FORMATS },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
